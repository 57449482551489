import { createAction, props } from '@ngrx/store';

export enum EventVariablesActions {
  SetFastSubActive = '[Test Variables] Set Fast Subscription Active Variable',
  SetIsCancellationFlowActive = '[Test Variables] Set Cancellation Flow',
  SetIsFreeUserRegistered = '[Test Variables] Set Is Free User Registered',
  SetIsUserMultitask = '[Test Variables] Set Is User Multitask',
  SetIsUserCompliance = '[Test Variables] Set Is User Compliance',
}

export const setCanFastActivateSubscription = createAction(
  EventVariablesActions.SetFastSubActive,
  props<{ value: boolean }>(),
);

export const setCancellationFlowIsActive = createAction(
  EventVariablesActions.SetIsCancellationFlowActive,
  props<{ value: boolean }>(),
);

export const setIsFreeUserRegistered = createAction(
  EventVariablesActions.SetIsFreeUserRegistered,
  props<{ value: boolean }>(),
);

export const setIsUserMultitask = createAction(
  EventVariablesActions.SetIsUserMultitask,
  props<{ value: boolean }>(),
);

export const setIsUserCompliance = createAction(
  EventVariablesActions.SetIsUserCompliance,
  props<{ value: boolean }>(),
);
